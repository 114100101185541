/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, ContactForm } from '@swp/components'
import SiteHeader from '../../components/en/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader />

        <Column className="--center pb--60 pt--60" name={"intro"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" style={{"maxWidth":1200}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"3"} style={{"maxWidth":900}}>
              
              <Title className="title-box fs--62 w--900" style={{"maxWidth":700}} content={"Contact"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"n02fz00obj"}>
          
          <ColumnWrap className="column__flex el--2 flex--top" style={{"maxWidth":1200}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Ing. Thomas Kamenovsky"}>
              </Subtitle>

              <Text className="text-box" content={"510-851-40X4<br>info@vase-stranky.com<br>722 Finch Street"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1 --right" action={"/contact"} fields={[{"name":"Full name","type":"text","required":true,"placeholder":"Enter your full name"},{"name":"Contact e-mail address:","type":"email","required":true,"placeholder":"Enter your e-mail"},{"name":"Message","type":"textarea","required":true,"placeholder":"Enter your message"},{"name":"Send","type":"submit","align":"left"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"blank"} layout={"l30"}>
        </Column>


        <Column className="css-g943z5 pb--80 pt--80" name={"contact"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: white;\">Let's Get More for Your Real Estate\n</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--20 pt--20" name={"footer"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left fs--20" content={"<span style='color: var(--color-dominant)'>Ing. Thomas Kamenovsky</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--right fs--20" content={"<span style='color: var(--color-dominant)'>2023 – Created with <a href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}